export const LINE_CHART_FIELDS = {
  pi: "Principal & Interest",
  homeInsurance: "Home Insurance",
  propertyTaxAmount: "Property Tax",
  mortgageInsurance: "Mortgage Insurance",
  hoaDues: "HOA Fees",
  monthly_debt: "Monthly Debt"
} as const

export const LINE_CHART_FIELDS_KEYS = Object.keys(
  LINE_CHART_FIELDS
) as (keyof typeof LINE_CHART_FIELDS)[]
