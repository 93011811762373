const bbEnv = (window as any).bbProcessEnv ?? {}

// Add any more variables that are needed
export const bbProcessEnv = {
  REVIEWS_CACHE_ENABLE:
    bbEnv.REVIEWS_CACHE_ENABLE || process.env.REVIEWS_CACHE_ENABLE,
  CAPTCHA_SITE_KEY: bbEnv.CAPTCHA_SITE_KEY || process.env.CAPTCHA_SITE_KEY,
  API_URL: bbEnv.API_URL || process.env.API_URL,
  PUBLIC_PATH: bbEnv.PUBLIC_PATH || process.env.PUBLIC_PATH,
  GOOGLE_PLACES_KEY: bbEnv.GOOGLE_PLACES_KEY || process.env.GOOGLE_PLACES_KEY,
  DASHBOARD_URL: bbEnv.DASHBOARD_URL || process.env.DASHBOARD_URL,
  GOOGLE_LOGIN_API_CLIENT_ID:
    bbEnv.GOOGLE_LOGIN_API_CLIENT_ID || process.env.GOOGLE_LOGIN_API_CLIENT_ID,
  APPLE_REDIRECT_URL:
    bbEnv.APPLE_REDIRECT_URL || process.env.APPLE_REDIRECT_URL,
  APPLE_CLIENT_ID: bbEnv.APPLE_CLIENT_ID || process.env.APPLE_CLIENT_ID,
  FACEBOOK_APP_ID: bbEnv.FACEBOOK_APP_ID || process.env.FACEBOOK_APP_ID,
  PRICING_CACHE_ENABLE:
    (bbEnv.PRICING_CACHE_ENABLE || process.env.PRICING_CACHE_ENABLE) === "true",
  SENTRY_AUTH_TOKEN: bbEnv.SENTRY_AUTH_TOKEN || process.env.SENTRY_AUTH_TOKEN
}
