const modalStyles = `
  .bankingbridge-iframe-in-modal {
    max-width: 830px;
    width: 100%;
    height:100%
  }
  .bankingbridge-modal-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    overflow-y: scroll;
    z-index: 2147483647;
    -webkit-overflow-scrolling: touch;
  }
  .bankingbridge-modal-wrapper-simple {
    height:100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: translate(0,0);
        -ms-transform: translate(0,0);
            transform: translate(0,0);
    -webkit-transition: all .4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -o-transition: all .4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all .4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .bankingbridge-modal-wrapper-widget {
    display: flex;
    flex-flow: column;
    align-items: center;
    border-width: 50px 0;
    border-color: transparent;
    border-style: solid;
  }

  @media (max-width: 767px){
    .bankingbridge-modal-wrapper-widget{
      border: 0;
    }
  }
  .bankingbridge-modal-iframe{
    z-index: 1;
    position: relative;
    min-width: 100vw;
  }
`
export default modalStyles
