import axios, { AxiosResponse } from "axios"
import { bbProcessEnv } from "../../helpers"

/**
 * this API is used to validate the contact information
 * GET /api/v1/validate-contact?email=stupid@aol.com
 * GET /api/v1/validate-contact?phone=1111111111
 */

interface IContactValidationResponseData {
  status: "success" | "error"
  message: string
}

interface IContactValidationParams {
  email?: string
  phone?: string
}

async function validateContact(
  params: IContactValidationParams
): Promise<IContactValidationResponseData> {
  try {
    const response: AxiosResponse<IContactValidationResponseData> =
      await axios.get(`${bbProcessEnv.API_URL}/api/v1/validate-contact`, {
        params
      })
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export default {
  email: async (email: string) => {
    return validateContact({ email })
  },
  phone: async (phone: string) => {
    return validateContact({ phone: "1" + phone })
  }
}
