import axios from "axios"
import { APICallWithCache, bbProcessEnv } from "../../helpers"
import { ONE_DAY_IN_MILLISECONDS } from "../../constants"
import { DynamicFormConfigResponse } from "../../types"

export default {
  get: async (): Promise<DynamicFormConfigResponse | undefined> => {
    try {
      const response = await APICallWithCache({
        url: `${bbProcessEnv.API_URL}/api/v1/contact-form`,
        cacheEnabled: true,
        // five days in milliseconds
        expires: 5 * ONE_DAY_IN_MILLISECONDS
      })

      return response
    } catch (error) {
      console.log(error)
    }
    return undefined
  }
}
