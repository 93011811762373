import { Fragment, RefObject, h } from "preact"
import { useState, useEffect, useRef } from "preact/hooks"
import { offset, getUrlVars, objToUrlParam } from "../helpers"
import { useEventListener, useFrameWidth } from "../hooks"
import { CSSProperties } from "preact/compat"

type Props = {
  isModal?: boolean
  isBar?: boolean
  config?: any
  onMessage?: (data: any, e: Event) => void
  url: string
  className?: string
  title?: string
  IDPrefix?: string
  iframeRef?: RefObject<HTMLIFrameElement>
}

const Frame = ({
  isModal,
  isBar,
  config,
  config: { defaults, iframeReferrerPolicy, referrerParam },
  onMessage,
  url,
  className = "",
  title,
  IDPrefix = "",
  iframeRef
}: Props) => {
  const frameTitle = title || `BankingBridge ${config.type} embed`
  const frameName = `bb-${isModal || isBar ? "modal" : "main"}`

  const [height, setHeight] = useState("100%")

  const [styles, setStyle] = useState<CSSProperties>({ minHeight: "285px" })
  const [frameURL, setFrameURL] = useState("")

  const IFRAME_ID = `${IDPrefix}${config.wrapperID}-${config.type}-${config.app_key}`

  const { widthStyle, calculateFrameWidth } = useFrameWidth({
    isModal: isModal || isBar,
    iframeID: IFRAME_ID
  })

  useEffect(() => {
    calculateFrameWidth()
  }, [])

  // Frame reference
  const frameRef = iframeRef ?? useRef<HTMLIFrameElement>(null)

  const handleFrameTasks = (e: Event) => {
    // this is used to receive messages from the child to the parent
    const data = (e as MessageEvent).data

    if ((e as MessageEvent).source === frameRef.current?.contentWindow) {
      if (data.action === "resize" && !isModal) {
        const styles: CSSProperties = {}

        if (config.type === "api" && (!isModal || !isBar)) {
          // we hide the
          frameRef.current?.style.setProperty("display", "none", "important")
        } else {
          styles.height = `${data.height}px`
          styles.transition = "height 0.15s ease"
        }

        setHeight(data.height)
        setStyle(styles)
      } else if (data.action === "scroll-to-anchor") {
        handleScroll(data)
      }

      onMessage?.(data, e)
    }
  }

  const handleScroll = (data: {
    wrapperID: string
    anchorOffset: { top: any }
  }) => {
    const wrapper = document.getElementById(data.wrapperID)
    const wrapperOffset = offset(wrapper as HTMLElement)
    const anchorOffsetTop = data.anchorOffset.top
    const scrollY = anchorOffsetTop + wrapperOffset.top
    window.scrollTo(window.scrollX, scrollY)
  }

  const handleResize = () => {
    calculateFrameWidth()
    frameRef.current?.contentWindow?.postMessage({ action: "resize" }, "*")
  }

  const syncConfig = (config: any) => {
    frameRef.current?.contentWindow?.postMessage(
      { action: "syncConfig", config: config },
      "*"
    )
  }

  useEventListener("resize", handleResize)
  useEventListener("message", handleFrameTasks)

  useEffect(() => {
    if (frameRef.current) {
      frameRef.current.onload = () => {
        handleResize()
        syncConfig(config || {})
      }
    }
  }, [config])

  useEffect(() => {
    // if there is global default pass them as url params
    let newURL = url
    if (!isModal && !isBar) {
      if (defaults) {
        newURL = objToUrlParam(newURL, defaults)
      }
      // Get parent URL vars
      let parentVars = getUrlVars()
      if (Object.keys(parentVars).length) {
        parentVars = { parent_params: JSON.stringify(parentVars) }
        newURL = objToUrlParam(newURL, parentVars)
      }

      // Set referrer
      if (referrerParam) {
        newURL = objToUrlParam(newURL, { referrer: document.location.origin })
      }
    }

    setFrameURL(newURL)
  }, [defaults, url, isModal, isBar])

  return (
    <Fragment>
      <iframe
        title={frameTitle}
        width="100%"
        height={height}
        style={{ ...styles, ...widthStyle }}
        frameBorder="0"
        allowFullScreen={true}
        scrolling="no"
        src={frameURL}
        ref={frameRef}
        id={IFRAME_ID}
        name={frameName}
        className={className}
        referrerpolicy={iframeReferrerPolicy ?? "unsafe-url"}
      />
    </Fragment>
  )
}

export default Frame
