import axios from "axios"
import { bbProcessEnv } from "../../helpers"
import { GetCode, GetCodeResponse } from "../../types"

const API_URL = "/api/v1/auth-code"

interface ValidateCode {
  code: string
  auth_request_id: number
}

export default {
  getCode: async ({ type, contact }: GetCode): Promise<GetCodeResponse> => {
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}${API_URL}?type=${type}&contact=${contact}`
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  validateCode: async ({ code, auth_request_id }: ValidateCode) => {
    try {
      const response = await axios.post(
        `${bbProcessEnv.API_URL}${API_URL}/send-code`,
        {
          code,
          auth_request_id
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}

// leadValidate: async (code, id) => {
//     try {
//       const response = await axios.get(
//         `${bbProcessEnv.API_URL}/lead-validate.json`,
//         {
//           params: {
//             code: code,
//             auth_request_id: id
//           },
//           withCredentials: true
//         }
//       )
//       return response.data
//     } catch (error) {
//       console.log(error)
//     }
//   },
