import dashIcon from "../assets/icons/ui-dashboard.svg"
import profileIcon from "../assets/icons/ui-profile.svg"
import historyIcon from "../assets/icons/ui-history.svg"
import comparisonIcon from "../assets/icons/ui-comparison.svg"

export const SD_TIMEOUT_SUBSCRIPTION_MODAL = 15000
export const SD_INTERVAL_SUBSCRIPTION_MODAL = 24 * 60 * 60 // a day

export const NO_EMAIL_PLACEHOLDER = "@bbplaceholder.temp"

export const HOME_PATH = "/home"
export const PROFILE_PATH = "/profile"
export const QUOTE_HISTORY_PATH = "/quote-history"
export const COMPARISON_RATES_PATH = "/comparison-rates"

// Nav items
export const SD_NAV_ITEMS = [
  {
    label: "My Loan Scenario",
    icon: dashIcon,
    path: HOME_PATH
  },
  {
    label: "Compare Rates",
    icon: comparisonIcon,
    path: COMPARISON_RATES_PATH
  },
  {
    label: "Quote History",
    icon: historyIcon,
    path: QUOTE_HISTORY_PATH
  },
  {
    label: "Profile",
    icon: profileIcon,
    path: PROFILE_PATH
  }
] as const
