import { MORTGAGE_MODAL, GLOBAL_MODAL } from "../../../constants"
import { openGlobalModal, openGlobalModalFromWithin } from "../base"

export const openMortgageCalc = (iframeID?: string) => {
  openGlobalModal(GLOBAL_MODAL, MORTGAGE_MODAL, iframeID)
}

export const openMortgageCalcFromWithin = (iframeID?: string) => {
  openGlobalModalFromWithin(GLOBAL_MODAL, MORTGAGE_MODAL, iframeID)
}
