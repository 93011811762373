import { useCallback, useEffect, useState } from "preact/hooks"

const useEventQueue = (eventName: string) => {
  const [eventCalled, setEventCalled] = useState(false)
  const [queue, setQueue] = useState<Array<() => void>>([])

  const runQueue = useCallback(() => {
    queue.forEach(callback => callback())
    setQueue([])
  }, [queue])

  useEffect(() => {
    const handleEvent = (event: MessageEvent) => {
      if (event.data === eventName) {
        setEventCalled(true)
        runQueue()

        // Remove the event listener as it is no longer needed
        window.removeEventListener("message", handleEvent)
      }
    }

    window.addEventListener("message", handleEvent)

    return () => {
      window.removeEventListener("message", handleEvent)
    }
  }, [eventName, runQueue])

  const call = useCallback(
    (callback: () => void) => {
      if (eventCalled) {
        callback()
      } else {
        setQueue(prevQueue => {
          if (!prevQueue.includes(callback)) {
            return [...prevQueue, callback]
          }
          return prevQueue
        })
      }
    },
    [eventCalled]
  )

  return call
}

export default useEventQueue
