import { ModalViews } from "../types"

export const GLOBAL_MODAL_IFRAME_NAME = "bb-modal"
export const GLOBAL_MODAL_LOADED = "GLOBAL_MODAL_LOADED"
export const API_MSG_PROMISE = "API_MSG_PROMISE"
export const MAIN_IFRAME_NAME = "bb-main"
export const INIT_GLOBAL_MODAL = "INIT_GLOBAL_MODAL"
export const GLOBAL_MODAL_MOUNTED = "GLOBAL_MODAL_MOUNTED"
export const TOGGLE_GLOBAL_MODAL = "TOGGLE_GLOBAL_MODAL"
export const UPDATE_GLOBAL_MODAL_CONFIG = "UPDATE_GLOBAL_MODAL_CONFIG"
export const UPDATE_GLOBAL_MODAL_IFRAME_STATE =
  "UPDATE_GLOBAL_MODAL_IFRAME_STATE"
export const CLOSE_GLOBAL_MODAL = "CLOSE_GLOBAL_MODAL"
export const CLOSE_LAST_ITEM_GLOBAL_MODAL = "CLOSE_LAST_ITEM_GLOBAL_MODAL"
export const SUBMIT_LOAN_DETAILS_FORM = "SUBMIT_LOAN_DETAILS_FORM"
export const LOAN_DETAILS_MOBILE_FORM = "LOAN_DETAILS_MOBILE_FORM"
export const EXIT_POP_UP_MODAL = "EXIT_POP_UP_MODAL"
export const SUBSCRIPTION_MODAL = "SUBSCRIPTION_MODAL"
export const DASH_CONFIRM_MODAL = "DASH_CONFIRM_MODAL"
export const LEAD_WORKFLOW_MODAL = "LEAD_WORKFLOW_MODAL"
export const MORTGAGE_MODAL = "MORTGAGE_MODAL"

export const MOBILE_FORM = "MOBILE_FORM"
export const GLOBAL_MODAL = "GLOBAL_MODAL"

export const EXIT_POP_UP_MOBILE_TIMER = 10000 // 10 seconds
export const BB_EXIT_POP_UP_STORAGE_KEY = "exitPopUp"

export const MODAL_VIEWS: ModalViews = {
  EXIT_POP_UP_MODAL: {
    title: "Exit PopUp",
    size: "sm",
    className: "global-exit-popup"
  },
  SUBSCRIPTION_MODAL: {
    title: "Subscription Modal",
    className: "subscription-modal"
  },
  DASH_CONFIRM_MODAL: {
    title: "Dashboard confirmation Modal",
    className: "dash-confirm-modal",
    size: "sm",
    hideBackButton: true
  },
  LEAD_WORKFLOW_MODAL: {
    title: "Lead Workflow Modal",
    className: "lead-workflow-modal is-height-auto"
  },
  MORTGAGE_MODAL: {
    title: "Mortgage Calculator Modal",
    className: "mortgage-calculator-modal is-height-auto",
    size: "lg",
    hasOnBackOrClose: true
  }
}
