import axios from "axios"
import { bbProcessEnv } from "../../helpers"

export default {
  manage: async ({ nmls, brand_id }: { nmls?: string; brand_id?: string }) => {
    let tmpParams = nmls ? `?nmls=${nmls}` : ""
    tmpParams += brand_id
      ? `${tmpParams.length ? "&" : "?"}gid=${brand_id}`
      : ""

    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/lo-manage.json${tmpParams}`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}
