import {
  BB_EXIT_POP_UP_STORAGE_KEY,
  EXIT_POP_UP_MODAL,
  GLOBAL_MODAL,
  ONE_DAY_IN_MILLISECONDS
} from "../../../constants"
import { getStorage, setStorage } from "../../../helpers"
import { openGlobalModal } from "../base"

export const openExitPopUp = (iframeID?: string) => {
  openGlobalModal(GLOBAL_MODAL, EXIT_POP_UP_MODAL, iframeID)
}

// Function to check if we should show the exit pop up, using local storage. Rules are:
// - If the user has already seen the exit pop up, we should not show it again until 24 hours have passed
// - If the user has not seen the exit pop up, we should show it
// - If the user has seen the exit pop up but 24 hours have passed, we should show it again
// - If the user has subscribed, we should not show the exit pop up
// - If a regular modal(any of the old modals) is opened, we should not show the exit pop up

// We will store the following in local storage:
// - exitPopUpSeen: boolean
// - exitPopUpLastSeen: number
// - exitPopUpSubscribed: boolean
export const shouldShowExitPopUp = () => {
  // get session storage to check if any regular modal is opened
  const regularModalOpened = sessionStorage.getItem("regularModalOpened")
  // if opened return
  if (regularModalOpened) {
    console.info(
      "Global modal can't be open, a regular modal is already opened"
    )
    return
  }

  const storage = getStorage(BB_EXIT_POP_UP_STORAGE_KEY)
  const exitPopUpSeen = storage?.exitPopUpSeen ?? false
  const exitPopUpLastSeen = storage.exitPopUpLastSeen ?? 0
  const exitPopUpSubscribed = storage.exitPopUpSubscribed ?? false

  if (exitPopUpSubscribed) {
    return false
  }

  if (!exitPopUpSeen) {
    // If the user has not seen the exit pop up, we should show it and update the last seen time
    setExitPopUpSeen()

    return true
  }

  const currentTime = new Date().getTime()
  const timePassed = currentTime - exitPopUpLastSeen
  const oneDay = ONE_DAY_IN_MILLISECONDS

  if (timePassed > oneDay) {
    // If the user has seen the exit pop up but 24 hours have passed, we should show it again
    setExitPopUpSeen()
    return true
  }

  return false
}

// Function to set the exit pop up as seen
export const setExitPopUpSeen = () => {
  const storage = getStorage(BB_EXIT_POP_UP_STORAGE_KEY)
  storage.exitPopUpSeen = true
  storage.exitPopUpLastSeen = new Date().getTime()

  setStorage(storage, BB_EXIT_POP_UP_STORAGE_KEY)
}

// Function to set the exit pop up as subscribed
export const setExitPopUpSubscribed = () => {
  const storage = {
    exitPopUpSubscribed: true,
    exitPopUpSeen: true,
    exitPopUpLastSeen: new Date().getTime()
  }

  setStorage(storage, BB_EXIT_POP_UP_STORAGE_KEY)
}
