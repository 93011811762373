import { h, ComponentChildren, Fragment } from "preact"
import Loader from "../loaders/Loader"

type Props = {
  condition: boolean
  elseCondition?: boolean
  isLoading?: boolean
  then: ComponentChildren
  else?: ComponentChildren
  default?: ComponentChildren
}

const If = ({
  condition,
  elseCondition = true,
  isLoading,
  then,
  else: elseComponent,
  default: defaultComponent
}: Props) => {
  if (isLoading) {
    return <Loader className="d-flex align-items-center" />
  }

  if (condition) {
    return <Fragment>{then}</Fragment>
  }

  if (elseCondition && elseComponent) {
    return <Fragment>{elseComponent}</Fragment>
  }

  return defaultComponent ? <Fragment>{defaultComponent}</Fragment> : null
}

export default If
