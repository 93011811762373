const modalStyles = `
  .bankingbridge-iframe-in-modal {
    max-width: 830px;
    width: 100%;
    height:100%
  }
  .bankingbridge-modal-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    overflow-y: scroll;
    z-index: 2147483647;
    -webkit-overflow-scrolling: touch;
  }
  .bankingbridge-modal-visible{
    display: none;
  }
  .bankingbridge-bar-iframe{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    min-width: 100vw;
    height: 0
  }
}`

export default modalStyles
