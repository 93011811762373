//import axios from "axios"

let measurements = [
  ":trackingId",
  ":apiVersion",
  ":clientVersion",
  ":location",
  ":referrer",
  ":screenResolution",
  ":screenColors",
  ":viewportSize",
  ":encoding",
  ":javaEnabled",
  ":language",
  ":_gclid",
  ":clientId",
  ":_gid",
  ":adSenseId",
  ":_s"
]

let gaPollingInterval = null

const GA_PROXY_URL = "https://api.bankingbridge.com/analytics"

export default {
  sendPageview: async info => {
    let params = await collectValues()
    if (!params) {
      return
    }
    params[":trackingId"] = info.trackerId
      ? info.trackerId
      : params[":trackingId"]
    params["hitType"] = "pageview"
    await transmit(params)
    window.bb_pageview = true
  },
  // info={
  //		name
  //		category
  //		label
  //		trackerId
  // }
  sendEvent: async info => {
    if (!info.name || !info.category || !info.label) {
      return
    }
    let params = await collectValues()
    if (!params) {
      return
    }
    if (!(await checkPageview())) {
      console.log("pageview was never sent")
      return
    }
    params[":trackingId"] = info.trackerId
      ? info.trackerId
      : params[":trackingId"]
    params["eventCategory"] = info.category
    params["eventLabel"] = info.label
    params["eventAction"] = info.name
    params["hitType"] = "event"
    return transmit(params)
  }
}

async function checkGA(timeoutMs = 5000) {
  let startTime = +new Date()
  //console.log("checking if device is up...");
  while (true) {
    let status =
      typeof ga === "function" &&
      ga &&
      ga.getAll &&
      typeof ga.getAll === "function" &&
      ga.getAll()[0]
    if (status) {
      //console.log("it's up!");
      return true
    } else {
      //console.log("still checking...");
    }
    await timeout(200)
    let now = +new Date()
    if (now - startTime > timeoutMs) {
      //console.log("timing out!");
      return false
    } else {
      //console.log((now-startTime)+" has elapsed");
    }
  }
}

async function checkPageview(timeoutMs = 5000) {
  let startTime = +new Date()
  //console.log("checking if device is up...");
  while (true) {
    let status = window.bb_pageview ? true : false
    if (status) {
      //console.log("it's up!");
      return true
    } else {
      //console.log("still checking...");
    }
    await timeout(500)
    let now = +new Date()
    if (now - startTime > timeoutMs) {
      //console.log("timing out!");
      return false
    } else {
      //console.log((now-startTime)+" has elapsed");
    }
  }
}

async function collectValues() {
  let result = {}
  if (await checkGA()) {
    for (let i = 0; i < measurements.length; i++) {
      let values = getValues(ga.getAll()[0], measurements[i])
      if (values[0]) {
        result[measurements[i]] = values[0]
      }
    }
  } else {
    console.log("ga is not ready")
  }
  result.documentTitle = document.title
  result.userAgent = navigator.userAgent
  return result
}

function transmit(params) {
  let body = {
    measurements: params
  }
  return fetch(GA_PROXY_URL, {
    headers: {},
    body: JSON.stringify(body),
    method: "POST",
    mode: "cors",
    credentials: "omit"
  })
}

function getValues(obj, key) {
  var objects = []
  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) continue
    if (typeof obj[i] == "object") {
      objects = objects.concat(getValues(obj[i], key))
    } else if (i == key) {
      objects.push(obj[i])
    }
  }
  return objects
}

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
