import { ONE_DAY_IN_MILLISECONDS } from "../../constants"
import { APICallWithCache, bbProcessEnv } from "../../helpers"
import { ReviewsAPIResponse } from "../../types"

const reviewsAPI = {
  // /api/v1/reviews/brand?id=<brand_id>
  // /api/v1/reviews/lo?id=<loid>

  get: async (id: string, type = "brand"): Promise<ReviewsAPIResponse> => {
    try {
      const response: ReviewsAPIResponse = await APICallWithCache({
        url: `${bbProcessEnv.API_URL}/api/v1/reviews/${type}?id=${id}`,
        cacheEnabled: bbProcessEnv.REVIEWS_CACHE_ENABLE,
        // five days in milliseconds
        expires: 5 * ONE_DAY_IN_MILLISECONDS
      })

      if (response.status === "error") {
        console.error(response.message)
        return {} as ReviewsAPIResponse
      }
      return response
    } catch (error) {
      console.log(error)
      return {} as ReviewsAPIResponse
    }
  }
}

export default reviewsAPI
