import axios from "axios"
import { bbProcessEnv } from "../../helpers"

export default {
  get: async ({ quote_id }) => {
    let id = ""
    if (Array.isArray(quote_id)) {
      id = quote_id.join(",")
    } else {
      id = quote_id
    }

    let url = `${bbProcessEnv.API_URL}/api/v1/fees`

    try {
      const response = await axios.get(url, {
        params: {
          quote_id: id
        }
      })
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}
