import { LoanDetailsFormFields, SubscriptionDashInterface } from "../types"

declare const rateflow_id: string | null
declare const bb_sid: string | null

declare const disclaimer: string | ""

const quote_info: LoanDetailsFormFields = {
  selectedTerm: 30,
  loan_amount: 70000,
  list_price: 350000,
  loan_purpose: "purchase",
  loan_type: "conventional",
  credit_score: 720,
  residency_type: "primary_home",
  property_type: "single_family_home",
  cash_out: 0,
  loan_term: 30,
  down_payment: 0,
  down_percentage: 0,
  zipcode: "",
  phone: "",
  va_eligible: false,
  is_fha: false,
  lock_period: 0,
  waive_escrows: false,
  first_time_homebuyer: false,
  va_funding_fee: false,
  location: ""
}

const subscriptionInitialState: Partial<SubscriptionDashInterface> = {
  disclaimer: typeof disclaimer !== "undefined" ? disclaimer : "",
  isLoading: true,
  isLoadingCards: true,
  isContactModalVisible: false,
  isWelcomeModalVisible: false,
  isOfferDetailsModalVisible: false,
  isRateTableModalVisible: false,
  isSubscribeModalVisible: false,
  quote_info,
  showNoEmailModal: false,
  active: "1",
  rateflow_id: typeof rateflow_id !== "undefined" ? rateflow_id : null,
  bb_sid: typeof bb_sid !== "undefined" ? bb_sid : null
}

export { subscriptionInitialState }
