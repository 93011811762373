import { useState } from "preact/hooks"

interface UseFrameWidth {
  isModal?: boolean
  iframeID: string
}

/**
 * This is used to resize the frame width when the is more than 32px
 * of difference between the frame and the parent
 * and avoid shrinking the frame width
 */
const useFrameWidth = ({ isModal, iframeID }: UseFrameWidth) => {
  const [widthStyle, setWidthStyle] = useState({})

  const calculateFrameWidth = () => {
    if (!isModal) {
      const parentWidth = document.body.clientWidth ?? 0

      // parent width is not mobile we return
      if (parentWidth > 768) {
        setWidthStyle({})
        return
      }

      const frameWidth = document.getElementById(iframeID)?.clientWidth ?? 0
      const diff = parentWidth - frameWidth

      if (diff > 32) {
        const gutter = diff / 2
        setWidthStyle({
          width: `calc(100% + ${gutter * 2}px`,
          marginLeft: `-${gutter}px`,
          marginRight: `-${gutter}px`,
          maxWidth: "none"
        })
      }
    }
  }

  return { widthStyle, calculateFrameWidth }
}

export default useFrameWidth
