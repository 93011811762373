import { h } from "preact"
import Cookies from "js-cookie"
import { useEffect, useState } from "preact/hooks"
import Frame from "./Frame"

const Content = ({ url, config, onMessage }) => {
  const [contentConfig, setContentConfig] = useState(config)
  const handleFrameTasks = (data, e) => {
    onMessage?.(data, e)
  }

  useEffect(() => {
    // if there are cookies we merge them with the config to use them in the iframe
    const cookies = Cookies.get()
    const newConfig = { ...config, cookies }
    setContentConfig(newConfig)
  }, [config])

  return <Frame url={url} onMessage={handleFrameTasks} config={contentConfig} />
}

export default Content
