import axios from "axios"
import {
  AffordabilityFormData,
  AffordabilityInitials,
  AffordabilityResponse
} from "../../types"
import { bbProcessEnv } from "../../helpers/bbProcessEnv"

interface IAffordabilityResponseData {
  data: AffordabilityResponse
}

interface IAffordabilityInitialsData {
  data: AffordabilityInitials
}

const ENDPOINT = `${bbProcessEnv.API_URL}/external/afford_calc.json`

const affordabilityAPI = {
  calculate: async (
    nmls: string | undefined,
    formData: AffordabilityFormData
  ): Promise<AffordabilityResponse> => {
    const nmlsQueryParam = nmls ? `?nmls=${nmls}` : ""
    try {
      const response: IAffordabilityResponseData = await axios.post(
        `${ENDPOINT}${nmlsQueryParam}`,
        {
          ...formData
        }
      )

      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  getInitials: async (nmls: string | undefined) => {
    const nmlsQueryParam = nmls ? `?nmls=${nmls}` : ""
    try {
      const response: IAffordabilityInitialsData = await axios.get(
        `${ENDPOINT}${nmlsQueryParam}`
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}

export default affordabilityAPI
