import axios from "axios"
import { bbProcessEnv } from "../../helpers"

export default {
  getConfig: async () => {
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/api/v1/rate-table-config`
      )
      return response?.data?.details ?? null
    } catch (error) {
      console.log(error)
    }
  }
}
