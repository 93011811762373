import { h } from "preact"
import { useState, useEffect } from "preact/hooks"
import Frame from "./Frame"
import modalStyles from "./bar-styles"

const Bar = ({ config, isBlocker, onMessage, barHREF, modalVisible }) => {
  const [wrapperClassName, setWrapperClassName] = useState("")

  useEffect(() => {
    if (isBlocker) {
      setWrapperClassName("bankingbridge-modal-wrapper")
      document
        .getElementsByTagName("html")[0]
        .classList.add("bankingbridge-page-scroll-locked")
    } else {
      setWrapperClassName("")
      document
        .getElementsByTagName("html")[0]
        .classList.remove("bankingbridge-page-scroll-locked")
    }
  }, [isBlocker])

  useEffect(() => {
    const className = " bankingbridge-modal-visible"
    const newClassName = modalVisible
      ? `${wrapperClassName}${className}`
      : wrapperClassName.replace(className, "")

    setWrapperClassName(newClassName)
  }, [modalVisible])

  const handleFrameTasks = (data, e) => {
    onMessage?.(data, e)
  }

  return (
    <div className={wrapperClassName}>
      <Frame
        url={barHREF}
        onMessage={handleFrameTasks}
        config={config}
        className="bankingbridge-bar-iframe"
        isBar
      />

      <style>{modalStyles}</style>
    </div>
  )
}

export default Bar
