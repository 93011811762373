import { bbProcessEnv, APICallWithCache } from "../../helpers"

interface TaxesAndInsuranceAPIResponse {
  (data: {
    zipcode?: number
    county: string
    list_price: number
    subscription_id?: number
  }): Promise<{ frequency: string; insurance: number; taxes: number }>
}

interface TaxesAndInsuranceAPI {
  get: TaxesAndInsuranceAPIResponse
}

const taxesAndInsuranceAPI: TaxesAndInsuranceAPI = {
  get: async ({ zipcode, county, list_price, subscription_id }) => {
    const params = {
      list_price,
      ...(zipcode ? { zipcode } : { county }),
      ...(subscription_id ? { subscription_id } : {})
    }

    const queryString = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join("&")

    try {
      const response = await APICallWithCache({
        url: `${bbProcessEnv.API_URL}/api/v1/taxes-and-insurance?${queryString}`,
        cacheEnabled: bbProcessEnv.PRICING_CACHE_ENABLE
      })

      return response
    } catch (error) {
      console.log(error)
    }
  }
}

export default taxesAndInsuranceAPI
