import axios from "axios"
import { bbProcessEnv } from "../../helpers"

export default {
  send: async eventData => {
    /*
    POST /api/v1/embed-events?app_key=<app_key>&bbToken=<bbToken>
    {
      name
      category
      app_key
    }
    *=required
    */
    try {
      const response = await axios.post(
        `${bbProcessEnv.API_URL}/api/v1/embed-events`,
        {
          ...eventData
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}
